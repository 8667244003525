
export const METRIC_SYSTEM = {
  IMPERIAL: 'Imperial',
  METRIC: 'Metric',
}

export const TRAFFIC_MODEL = {
  OPTIMISTIC: 'Optimistic',
  BEST_GUESS: 'Best guess',
  PESSIMISTIC: 'Pessimistic',
}

export const FUEL_TYPE = {
  PETROL: _ => !!_?.match(/unleaded|petrol/),
  DIESEL: _ => !!_?.match("diesel"),
  ELECTRIC: _ => _ === 'electric',
}