import './index.css';
import './theme.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';
import App from './App';
import theme from './theme';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <CssVarsProvider theme={theme} defaultMode='system'>
      <App />
    </CssVarsProvider>
  </React.StrictMode>
);

import('./reportWebVitals');
