import { useEffect, useState } from "react"
import { METRIC_SYSTEM, TRAFFIC_MODEL } from "./constants"
import { parse as parseCsv } from 'csv-parse/browser/esm';

const CAR_API_HOST = process.env.REACT_APP_RAPID_API_HOST
const HEADERS = {
  'X-RapidAPI-Key': process.env.REACT_APP_RAPID_API_KEY,
  // 'X-RapidAPI-Host': 'car-api2.p.rapidapi.com'
}

// export const carApiLogin = async () => {
//   const response = await fetch(`${CAR_API_HOST}/auth/login`, {
//     method: 'POST',
//     headers: HEADERS,
//     body: JSON.stringify({
//       api_token: process.env.REACT_APP_CAR_API_TOKEN,
//       api_secret: process.env.REACT_APP_CAR_API_SECRET,
//     })
//   })
//   const data = await response.json()
//   sessionStorage.setItem('carApiToken', data.token)
//   return data.token
// }

// Get European petrol prices
// ? Alternative eu petrol prices source for crawling: https://www.cargopedia.net/europe-fuel-prices
// * US: https://www.fueleconomy.gov/ws/rest/fuelprices
export const usePetrolPrices = () => {
  const [prices, setPrices] = useState({})

  useEffect(() => {
    const fetchPrices = async () => {
      const response = await fetch('https://static.dwcdn.net/data/MbFeC.csv', {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        }
      })
      
      const data = await response.text()
      parseCsv(data, {}, (err, _data) => {
        
        const pricesPerCountry = _data.reduce((acc, row) => {
          const [_country, petrol, diesel] = row
          if (_country === 'Country') {
            return acc
          }
          if (_country === "Average") {
            acc['Average'] = { petrol, diesel }
            return acc
          }
          const countryItems = _country.split(":")
          const country = countryItems[0].trim()
          const countryCode = countryItems[1].trim()
          acc[country] = { petrol, diesel }
          acc[countryCode] = { petrol, diesel }
          return acc
        }, {})

        setPrices(pricesPerCountry)
      });
    }
    fetchPrices();
  }, [])

  return { prices }
}

export const useCarMakes = () => {
  const [makes, setMakes] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetchMakes = async () => {
      setIsLoading(true)
  
      try {
        const res = await fetch(`${CAR_API_HOST}/makes?sort=name&direction=asc`, {
          headers: HEADERS
        })
        const { data } = await res.json()
        setMakes(data)
        setIsLoading(false)
      }  catch (err) {
        console.error(err)
        setMakes([])
        setIsLoading(false)
      }
    }
    fetchMakes();
  }, [])

  return { makes, isLoading }
}

export const useCarModels = make => {
  const [models, setModels] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetchModels = async () => {
      if (!make?.id) {
        return
      }
      setIsLoading(true)
  
      try {
        // * Free version of API is limited to 2020 models
        const res = await fetch(`${CAR_API_HOST}/models/?make_id=${make.id}&year=2020&sort=name&direction=asc`, {
          headers: HEADERS,
        })
        const { data } = await res.json()
        setModels(data)
        setIsLoading(false)
      } catch (err) {
        console.error(err)
        setModels([])
        setIsLoading(false)
      }
    }

    fetchModels();
  }, [make])

  return { models, isLoading }
}

export const useCarTrims = (model) => {
  const [trims, setTrims] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetchTrims = async () => {
      if (!model?.id) {
        return
      }
      setIsLoading(true)
      
      try {
        // * Free version of API is limited to 2020 models
        const res = await fetch(`${CAR_API_HOST}/trims/?make_model_id=${model.id}&model=${model.name}&year=2020&sort=name&direction=asc`, {
          headers: HEADERS
        })
        var { data } = await res.json()
        // * fix duplicates
        // data = data.filter((trim, index, self) =>
        //   index === self.findIndex((t) => (
        //     t.name === trim.name
        //   ))
        // )
        setTrims(data)
        setIsLoading(false)
      } catch (err) {
        console.error(err)
        setTrims([])
        setIsLoading(false)
      }
    }

    fetchTrims();
  }, [model])

  return { trims, isLoading }
}

export const useCarMileage = (trim) => {
  const [mileage, setMileage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetchTrims = async () => {
      if (!trim?.id) {
        return
      }
      setIsLoading(true)
  
      try {
        const res = await fetch(`${CAR_API_HOST}/mileages/?make_model_trim_id=${trim.id}&trim=${trim.name}`, {
          headers: HEADERS
        })
        const { data } = await res.json()
        setMileage(data[0] || null)
        setIsLoading(false)
      } catch (err) {
        console.error(err)
        setMileage(null)
        setIsLoading(false)
      }
    }

    fetchTrims();
  }, [trim])

  return { mileage, isLoading }
}

export const useCarEngine = (trim) => {
  const [engine, setEngine] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetchEngine = async () => {
      if (!trim?.id) {
        return
      }
      setIsLoading(true)
  
      try {
        const res = await fetch(`${CAR_API_HOST}/engines/?make_model_trim_id=${trim.id}&trim=${trim.name}`, {
          headers: HEADERS
        })
        const { data } = await res.json()
        setEngine(data[0] || null)
        setIsLoading(false)
      } catch (err) {
        console.error(err)
        setEngine(null)
        setIsLoading(false)
      }
    }

    fetchEngine();
  }, [trim])

  return { engine, isLoading }
}

export const useCarDetailsFromUKNumberPlate = (numberPlate) => {
  const [carDetails, setCarDetails] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [timeoutId, setTimeoutId] = useState(null)
  
  useEffect(() => {
    if (!numberPlate || numberPlate?.length < 3) {
      return
    }

    if (timeoutId) {
      clearTimeout(timeoutId)
    }

    const fetchCarDetails = async () => {
      try {
        const response = await fetch('/.netlify/functions/dvla', {
          method: 'POST',
          body: JSON.stringify({ registrationNumber: numberPlate.trim() })
        })
        setCarDetails(await response.json())
      } catch (err) {
        console.error(err)
        setCarDetails(null)
      }
      setIsLoading(false)
    }
    setIsLoading(true)
    setTimeoutId(setTimeout(fetchCarDetails, 1000))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberPlate])

  return { carDetails, isLoading }
}

export const trafficModelOptionToGoogleMapsOption = (option, GoogleMapsTrafficModel) => {
  switch (option) {
    case TRAFFIC_MODEL.OPTIMISTIC:
      return GoogleMapsTrafficModel.OPTIMISTIC
    case TRAFFIC_MODEL.PESSIMISTIC:
      return GoogleMapsTrafficModel.PESSIMISTIC
    case TRAFFIC_MODEL.BEST_GUESS:
      return GoogleMapsTrafficModel.BEST_GUESS
    default:
      return TRAFFIC_MODEL.OPTIMISTIC
  }
}

// TODO double check the format in which the CarAPI returns the data
export const formatMileRange = (range, metricSystem) => {
  if (!range) {
    return '-'
  }
  range = range > 999 ? Math.floor(range / 10) : range
  if (metricSystem === METRIC_SYSTEM.METRIC) {
    return `${range} km`
  }
  return `${Math.round(range / 1.60934)} mi`
}

export const getConsumptionInMpg = (consumption, metricSystem) => {
  if (!consumption) {
    return null
  }
  if (metricSystem === METRIC_SYSTEM.METRIC) {
    // convert to mpg
    return Math.round(282.5 / consumption)
  }
  return consumption
}

export const formatMpgConsumption = (consumption, metricSystem) => {
  if (!consumption) {
    return '-'
  }
  if (metricSystem === METRIC_SYSTEM.METRIC) {
    return `${Math.round(282.5 / consumption)} L/100km`
  }
  return `${consumption} mpg`
}

export const formatGallonCapacity = (capacity, metricSystem) => {
  if (!capacity) {
    return '-'
  }
  if (metricSystem === METRIC_SYSTEM.METRIC) {
    return `${Math.round(capacity * 3.78541)} L`
  }
  return `${capacity} gal`
}

// * Distance is always in meters: https://developers.google.com/maps/documentation/javascript/directions#UnitSystems
export const getMilesFromDistance = (distance) => {
  return distance / 1000 / 1.609
}

export const pencePerLiterToGallon = price => price * 4.546