import { experimental_extendTheme as extendTheme } from '@mui/material/styles';

const disabledSx = {
  '--mui-palette-FilledInput-disabledBg': 'rgba(0 0 0 / 12%)',
  cursor: 'not-allowed',
  pointerEvents: 'initial',
}

const theme = extendTheme({
  typography: {
    fontFamily: [
    'Geologica', 
    'sans-serif'
    ].join(','),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 425,
      md: 768,
      lg: 1200,
      xl: 1536,
    },
  },
  colorSchemes: {
    light: {
      palette: {
        primary: {
          main: '#006A6A',
          contrastText: '#FFFFFF',
        },
        secondary: {
          main: '#4A6363',
          contrastText: '#FFFFFF',
        },
        error: {
          light: '#FFDAD6',
          main: '#BA1A1A',
          dark: '#410002',
          contrastText: '#FFFFFF',
        },
        common: {
          onBackgroundChannel: '76 218 218',
        },
        text: {
          primaryChannel: '76 218 218',
          primary: '#191C1C',
          secondary: '#3F4948',
          disabled: '#2D3131',
        },
        background: {
          default: '#FFFFFF',
          paper: '#006A6A',
        },
        action: {
          disabled: '#BEC9C8'
        }
      }
    },
    dark: {
      palette: {
        primary: {
          main: '#4CDADA',
          contrastText: '#003737',
        },
        secondary: {
          main: '#B0CCCB',
          contrastText: '#1B3534',
        },
        error: {
          light: '#FFDAD6',
          main: '#FFB4AB',
          dark: '#93000A',
          contrastText: '#690005',
        },
        common: {
          onBackgroundChannel: '76 218 218',
        },
        text: {
          primaryChannel: '76 218 218',
          primary: '#6FF7F6',
          secondary: '#BEC9C8',
          disabled: '#E0E3E2',
        },
        background: {
          default: '#003737',
          paper: '#191C1C',
        },
        action: {
          disabled: '#3F4948'
        }
      }
    }
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: ({ theme })=> ({
          color: theme.palette.mode === 'dark'
            ? 'var(--mui-palette-primary-light)'
            : 'var(--mui-palette-primary-contrastText)',
        })
      }
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: 9999,
          padding: '12px 16px',
          '&:hover': {
            backgroundColor: 'var(--md-sys-color-secondary-container)',
          },
          '&.Mui-selected': {
            backgroundColor: 'var(--mui-palette-primary-light)',
            color: 'var(--mui-palette-primary-contrastText)',
            '&:hover': {
              backgroundColor: 'var(--mui-palette-primary-light)',
            }
          },
        },
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 9999,
          '&.Mui-disabled': disabledSx
        },
        input: {
          '&.Mui-disabled': disabledSx
        },
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiFormLabel-root.MuiInputLabel-root': {
            left: 4
          },
          '& .MuiInputBase-root.MuiFilledInput-root': {
            paddingLeft: 12,
          }
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          border: 'none',
          borderRadius: 9999,
          paddingLeft: 12,
          '--mui-palette-FilledInput-bg': 'var(--md-sys-color-secondary-container)',
          color: 'var(--md-sys-color-on-secondary-container)',

          '&.Mui-disabled': disabledSx,
          '&:hover:not(.Mui-disabled, .Mui-error):before': {
            borderColor: 'rgba(var(--mui-palette-common-onBackgroundChannel) / var(--mui-opacity-inputUnderline))',
          },
          '&.Mui-focused:before': {
            borderColor: 'rgba(var(--mui-palette-primary-darkChannel) / var(--mui-opacity-inputUnderline))',
          },
          '&:before': {
            height: '100%',
            border: '1px solid transparent',
            borderRadius: 9999,
            transition: 'border-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
          },
          '&:after': {
            display: 'none'
          }
        },
        input: {
          '&.Mui-disabled': disabledSx
        },
      }
    },
    MuiInput: {
      styleOverrides: {
        input: {
          '&.Mui-disabled': disabledSx
        },
        root: {
          '&.Mui-disabled': disabledSx
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': disabledSx
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&.Mui-disabled': disabledSx
        }
      }
    },
  }
});

export default theme;