import { Box, TextField } from '@mui/material'
import React, { lazy, useEffect, useState, Suspense } from 'react'
import { getConsumptionInMpg, useCarDetailsFromUKNumberPlate } from './utils'
import { METRIC_SYSTEM } from './constants'

const DvlaCarDetails = lazy(() => import('./DvlaCarDetails'))

const inputSx = {
  background: 'linear-gradient(to bottom, #f8d038 0%,#f5ca2e 100%)',
  fontWeight: 'bold',
  fontSize: '2em',
  borderRadius: '5px',
  border: '1px solid #000',
  position: 'relative',
  fontFamily: 'inherit',
  textTransform: 'uppercase',
  display: 'block',
  padding: '7px 10px 7px 46px',
  width: 195,
  // For placeholder text
  '--md-sys-color-on-background': '#666',
}

const gbSymbolSx = {
  border: '1px solid #000',
  content: '"GB"',
  fontFamily: 'inherit',
  fontWeight: 'bold',
  display: 'block',
  width: 36,
  height: '100%',
  fontSize: '1em',
  background: '#063298',
  position: 'absolute',
  top: 0,
  borderRadius: '5px 0 0 5px',
  color: '#f8d038',
  lineHeight: '85px',
  paddingLeft: '8px',

  '&:after': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: 7,
    left: 8,
    width: 20,
    height: 20,
    borderRadius: '30px',
    border: '1px dashed #f8d038',
  }
}

const UkNumberPlateInput = ({ onMileageChange, metricSystem, mileage, numberPlate, setNumberPlate }) => {
  const [consumption, setConsumption] = useState(Math.round(metricSystem === METRIC_SYSTEM.IMPERIAL ? 282.5 / 40 : 282.5 / 10));
  const { carDetails, isLoading } = useCarDetailsFromUKNumberPlate(numberPlate);

  const onNumberPlateChange = (e) => {
    const { value } = e.target;
    setNumberPlate(value);
  }

  useEffect(() => {
    if (consumption && carDetails?.fuelType && numberPlate) {
      onMileageChange({
        combined_mpg: getConsumptionInMpg(consumption, metricSystem),
        fuel_type: carDetails.fuelType.toLowerCase(),
        dvla: true,
      })
    }
  }, [consumption, carDetails, metricSystem, numberPlate, onMileageChange])

  // * Update consumption when metric system changes to reflect correct value in consumption input field
  useEffect(() => {
    setConsumption(Math.round(282.5 / consumption))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metricSystem])

  return (
    <>
      <Box className="input-box" sx={{
        fontFamily: 'CharlesWright, helvetica, ariel, sans-serif',
        my: '2rem',
        position: 'relative',

        '& > input': inputSx,
        '& > span': gbSymbolSx,
        '--md-circular-progress-active-indicator-color': '#f5ca2e',
        '& > md-circular-progress': {
          position: 'absolute',
          left: 'calc(100% + 12px)',
          top: '50%',
          transform: 'translateY(-50%)',
        }
      }}>
        <label htmlFor='uk-number-plate-input' hidden>UK number plate</label>
        <input 
          onChange={onNumberPlateChange}
          autoComplete="off"
          type="text"
          name="numberPlate"
          id='uk-number-plate-input'
          placeholder='UK number plate'
        />
        <span>GB</span>

        {isLoading && <md-circular-progress indeterminate></md-circular-progress>}
      </Box>

      {(carDetails?.fuelType && !isLoading && mileage?.dvla) && (
        <>
          <TextField
            label={`Consumption ${metricSystem === METRIC_SYSTEM.IMPERIAL ? '(mpg)' : '(l/100km)'}`}
            sx={{ mt: '-2rem' }}
            placeholder='your average consumption'
            variant='filled'
            type='text'
            value={consumption}
            onChange={(e) => setConsumption(e.target.value)}
          />
          <Suspense fallback={<md-circular-progress indeterminate></md-circular-progress>}>
            <DvlaCarDetails carDetails={carDetails} />
          </Suspense>
        </>
      )}
    </>
  )
}

export default UkNumberPlateInput