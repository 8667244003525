import '@material/web/circularprogress/circular-progress'
import '@material/web/button/tonal-button'
import '@material/web/iconbutton/filled-tonal-icon-button'
import '@material/web/icon/icon'
import { Box, Divider } from '@mui/material'

import Map from './Map';
import { useEffect, useState, useCallback, lazy, Suspense } from 'react';
import { FUEL_TYPE, METRIC_SYSTEM, TRAFFIC_MODEL } from './constants';
import { getMilesFromDistance, pencePerLiterToGallon, usePetrolPrices } from './utils';
import UkNumberPlateInput from './UkNumberPlateInput';

const Settings = lazy(() => import('./Settings'));
const CostIndicator = lazy(() => import('./CostIndicator'));
const CarMileageTable = lazy(() => import('./CarMileageTable'));
const CarForm = lazy(() => import('./CarForm'));
const FeedbackForm = lazy(() => import('./FeedbackForm'));

const DEFAULT = {
  TRAFFIC_MODEL: TRAFFIC_MODEL.OPTIMISTIC,
  METRIC_SYSTEM: METRIC_SYSTEM.METRIC,
  MILEAGE: 0,
  DISTANCE: 0,
  COST: 0,
}

function App() {
  const [isLoading, setIsLoading] = useState(false);
  const [metricSystem, setMetricSystem] = useState(DEFAULT.METRIC_SYSTEM);
  const [trafficModel, setTrafficModel] = useState(DEFAULT.TRAFFIC_MODEL);
  const [mileage, setMileage] = useState(null);
  const [distance, setDistance] = useState(null);
  const [costPounds, setCostPounds] = useState(null);
  const [fromCountry, setFromCountry] = useState(null);
  const [toCountry, setToCountry] = useState(null);
  const [pricePencePerLiter, setPricePencePerLiter] = useState(null);
  const [showMileageTable, setShowMileageTable] = useState(false);
  const [showCarForm, setShowCarForm] = useState(false);
  const [numberPlate, setNumberPlate] = useState('');

  const { prices } = usePetrolPrices();

  const onResetClick = useCallback(() => {
    window.location.reload();
  }, []);

  const onMetricSystemChange = useCallback(event => {
    setMetricSystem(event.target.value);
  }, [])
  const onTrafficModelChange = useCallback(event => {
    setTrafficModel(event.target.value);
  }, [])

  const calculatePirceForMileage = useCallback(() => {
    const fuelType = FUEL_TYPE.PETROL(mileage.fuel_type)
      ? 'petrol'
      : FUEL_TYPE.DIESEL(mileage.fuel_type)
        ? 'diesel'
        : 'electric';
    if (fuelType === 'electric') {
      window.alert('Electric vehicles are not supported yet')
      return;
    }

    const fromCountryPrice = Number((prices[fromCountry.code?.toLowerCase()] || prices[fromCountry.name])?.[fuelType]);
    const toCountryPrices = Number((prices[toCountry.code?.toLowerCase()] || prices[toCountry.name])?.[fuelType]);
    const fuelPriceCountriesAverage = Math.round((fromCountryPrice + toCountryPrices) / 2);
    const _pricePencePerLiter = Number.isNaN(fuelPriceCountriesAverage)
      ? (fromCountryPrice || toCountryPrices)
      : fuelPriceCountriesAverage
    const cost = Math.floor((getMilesFromDistance(distance) * pencePerLiterToGallon(_pricePencePerLiter) / mileage.combined_mpg) / 100);

    setCostPounds(cost);
    setPricePencePerLiter(_pricePencePerLiter);
  }, [distance, fromCountry, mileage?.combined_mpg, mileage?.fuel_type, prices, toCountry])

  /**
   *
   * @param {Object} response
   * @param {String} fromCountry.code
   * @param {String} fromCountry.name
   * @param {String} toCountry.code
   * @param {String} toCountry.name
   */
  const onDistanceChange = useCallback((response, fromCountry, toCountry) => {
    setTimeout(() => {
      setFromCountry(fromCountry);
      setToCountry(toCountry);
    }, 0)

    setDistance(response.routes[0].legs[0].distance.value)
  }, [])

  useEffect(() => {
    if (distance
      && mileage?.combined_mpg
      && mileage?.fuel_type
      && fromCountry
      && toCountry
    ) {
      const calculate = async () => {
        setIsLoading(true);
        await new Promise(resolve => setTimeout(() => {
          calculatePirceForMileage();
          resolve();
        }, 0));
        setIsLoading(false);
      }
      calculate();
    }
  }, [distance, mileage?.combined_mpg, mileage?.fuel_type, fromCountry, toCountry, calculatePirceForMileage])

  const onShowCarForm = () => {
    setShowCarForm(true);
    setMileage(null);
    setNumberPlate('');
  }

  return (
    <Box maxWidth='lg' sx={{ position: 'relative', m: '0 auto', pb: 10 }}>

      {isLoading && (
        <div className='loading-indicator'>
          <md-circular-progress indeterminate></md-circular-progress>
        </div>
      )}

      <Suspense>
        <Settings
          onResetClick={onResetClick}
          metricSystem={metricSystem}
          onMetricSystemChange={onMetricSystemChange}
          trafficModel={trafficModel}
          onTrafficModelChange={onTrafficModelChange}
        />
      </Suspense>

      <Map
        onDistanceChange={onDistanceChange}
        metricSystem={metricSystem}
        trafficModel={trafficModel}
      />

      <Box sx={{
        p: 2,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '1rem',
        flexWrap: 'wrap',
      }}>
        <UkNumberPlateInput
          mileage={mileage}
          onMileageChange={setMileage}
          metricSystem={metricSystem}
          setNumberPlate={setNumberPlate}
          numberPlate={numberPlate}
        />

        <Divider sx={{ maxWidth: 300, width: '100%' }}>OR</Divider>

        <Box sx={{
          width: '100%',
          textAlign: 'center',
          position: 'relative',
          '--md-elevation-level': showCarForm ? '2' : 0,
          borderRadius: 4,
          px: 1,
          pt: 2,
          pb: 4,
          mb: showCarForm && !mileage ? 15 : 0,
        }}>
          <md-elevation></md-elevation>
          <Box sx={{
            transition: 'transform 0.3s ease-in-out',
            transform: showCarForm ? 'translateX(calc(50vw - 48px))' : 'translateX(0)',
            display: 'inline-block',
          }}>
            {showCarForm ? (
              <md-filled-tonal-icon-button
                aria-label='Close car details form'
                onClick={() => setShowCarForm(false)}
              >
                <md-icon tabIndex={-1}>close</md-icon>
              </md-filled-tonal-icon-button>
            ) : (
              <md-tonal-button onClick={onShowCarForm}>
                Enter car model manually
              </md-tonal-button>
            )}
          </Box>

          {showCarForm && (
            <Suspense fallback={<md-circular-progress indeterminate></md-circular-progress>}>
              <CarForm onMileageChange={setMileage} metricSystem={metricSystem} />
            </Suspense>
          )}
        </Box>
      </Box>

      {(mileage && !mileage.dvla)&& (
        <Box sx={{
          p: '2rem',
          pt: showMileageTable ? '2rem' : 0,
          pb: 15,
          transition: 'all 0.3s ease-in-out',
          backgroundColor: showMileageTable ? 'var(--md-sys-color-background)' : 'transparent',
          transform: showMileageTable ? 'translateY(0)' : 'translateY(50%)',
        }}>
          <md-tonal-button onClick={() => setShowMileageTable(!showMileageTable)}>
            {showMileageTable ? 'Hide ' : 'Show '}car details
          </md-tonal-button>
          {showMileageTable && (
            <Suspense fallback={<md-circular-progress indeterminate></md-circular-progress>}>
              <CarMileageTable metricSystem={metricSystem} mileage={mileage} />
            </Suspense>
          )}
        </Box>
      )}

      {costPounds && (
        <Suspense fallback={<md-circular-progress indeterminate></md-circular-progress>}>
          <CostIndicator costPounds={costPounds} pricePencePerLiter={pricePencePerLiter} />
        </Suspense>
      )}

      <Suspense><FeedbackForm /></Suspense>
    </Box>
  );
}

export default App;
