import './Autocomplete.css'
import React, { useEffect, useRef } from 'react'
import { TextField } from '@mui/material';

const options = {
  fields: ['formatted_address', 'geometry', 'name', 'address_components'],
  strictBounds: false,
  types: ['administrative_area_level_3', 'locality'],
};

const Autocomplete = ({ 
  maps,
  onMarkerFromChange,
  onMarkerToChange,
  onSwapMarkers,
}) => {
  const inputARef = useRef();
  const inputBRef = useRef();
  const [fromListener, setFromListener] = React.useState(null);
  const [toListener, setToListener] = React.useState(null);

  useEffect(() => {
    if (!maps || !inputARef.current || !inputBRef.current) return;

    function placeChangeEventListener(autocomplete, updateFn) {
      const place = autocomplete.getPlace();
      if (!place.address_components || !place.geometry || !place.geometry.location) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        return;
      }
      
      const placeCountryDetails = place.address_components.find(_ => _.types.find(_ => _ === 'country'))
      const country = { name: placeCountryDetails.long_name, code: placeCountryDetails.short_name }
      updateFn(place.geometry.location, country);
    }
    async function registerAutocompleteEventListeners() {
      if (fromListener) {
        maps.event.removeListener(fromListener);
      }
      if (toListener) {
        maps.event.removeListener(toListener);
      }
      const { Autocomplete } = await maps.importLibrary("places");
      const autocompleteA = new Autocomplete(inputARef.current, options);
      const autocompleteB = new Autocomplete(inputBRef.current, options);

      setFromListener(autocompleteA.addListener("place_changed", 
        () => placeChangeEventListener(autocompleteA, onMarkerFromChange)));
      setToListener(autocompleteB.addListener("place_changed", 
        () => placeChangeEventListener(autocompleteB, onMarkerToChange)));
    }

    registerAutocompleteEventListeners()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maps, onMarkerFromChange, onMarkerToChange])

  const onSwapClick = (event) => {
    const inputAValue = inputARef.current.value;
    const inputBValue = inputBRef.current.value;
    inputARef.current.value = inputBValue;
    inputBRef.current.value = inputAValue;
    onSwapMarkers()
  }

  return (
    <div className='autocomplete-container'>
      <TextField
        fullWidth
        label='From location'
        placeholder='From location'
        variant='filled'
        type='text'
        inputProps={{ 'aria-label': 'From location', ref: inputARef }}
      />

      <md-filled-tonal-icon-button 
        aria-label='Swap from and to locations' 
        onClick={onSwapClick}
      >
        <md-icon tabIndex={-1}>swap_horiz</md-icon>
      </md-filled-tonal-icon-button>
      

      <TextField
        fullWidth
        label='To location'
        placeholder='To location'
        variant='filled'
        type='text'
        inputProps={{ 'aria-label': 'To location', ref: inputBRef }}
      />
    </div>
  )
}

export default Autocomplete